@import url('https://fonts.googleapis.com/css?family=Lato|Sulphur+Point:700&display=swap');

/*-----Containers-----*/
.html {
  font-family: 'Sulphur Point', sans-serif;
}
.login {
  text-align: center;
  width: 30%;
  margin-left: auto;
  margin-right: auto;
}

.signUp {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.passwordReset {
  align-items: center;
  text-align: center;
}

.feedbackTextArea {
  white-space: pre-wrap;
}

/*-----Typography-----*/
a {
  color: #8C1515; /* Stanford Red */
}

a:hover, a:active {
  color: gray; /* Indigo Dye */
  text-decoration: none;
}

.headers {
  font-family: 'Sulphur Point', sans-serif;
  word-spacing: -.27em;
}

.branding {
  margin-top:20%;
  text-align: right;
  float:right;
  top: calc(100vh - 200px);
  padding-right: 2%;
  display: flex;
  z-index:9999;
  padding-left: 5%;
}

.logo {
  float: left;
  text-align: left !important;
  width: 200px;
}

.title {
  font-size: 4.75rem;
  color: #8C1515;
}

.steps_titles {
  font-size: 2rem;
}


/*-----ReactStrap Tweaks-----*/

.form-control {
  min-width: 10rem;
}

button {
  background-color: #8C1515;
  border:none;
  border-radius: 5px 5px 5px 5px;
  font-family: "Sulphur Point", sans-serif;
  color: white;
}

button:active,
button:hover,
button:focus {
  background-color: #8C1515;
  outline: none;
}

.btn {
  border:none;
  border-radius: 4px;
  font-family: "Sulphur Point", sans-serif;
  word-spacing: -.27em;
  width: 10rem;
  margin: 2% 2% 2% 2%;
}

.btn-primary {
  background-color: #8C1515; /* Stanford Red */
}

.btn-secondary {
}

.btn-primary:active,
.btn-primary:hover,
.btn-primary:focus {
  background-color: #8C1515; /* Stanford Red */
  background-image: linear-gradient(-90deg, #620e0e, #8C1515);
}

label {
  padding: 10px 10px 10px 10px;
}

textarea {
  border-radius: 5px;
  width: 30rem;
  height: 15rem;
}

table {
  table-layout:fixed;
}

.bottom-buffer {
  margin-bottom: 5%;
  margin-top: 5%;
}